<template>
  <div class="List">
    <van-nav-bar title="GPS信息" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" class="list-box">
        <CaseGps v-for="(item, index) in list" :item="item" :key="index" class="item" @actionClick="onActionClick">
        </CaseGps>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
// import Vue from "vue";
import { Toast } from "vant";
import ApiDetail from "@/api/caseDetail";
import { wgs84ToGcj02, goMap } from '@/libs/utils'

export default {
  props: {},
  components: {},
  data() {
    return {
      bizNo: this.$route.query.bizNo || "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNo: 1,
      pageSize: 10,
      locationList: [],
    };
  },
  computed: {

  },
  methods: {
    async onLoad() {
      try {
        this.finished = false;
        this.loading = true;
        const { pageNo, pageSize } = this;
        const params = {
          pageNo,
          pageSize,
          bizNo: this.bizNo
        };

        let res = await ApiDetail.gpsInfo(params);
        let { data: list } = res;
        list = list.map(item => {
          let selectImei = this.locationList.find(e => e.imei == item.imei) || {}
          return {
            ...item,
            address: selectImei.address
          }
        })
        if (this.pageNo == 1) {
          this.list = list;
        } else {
          this.list = [...this.list, ...list];
        }
        this.finished = true;
        this.pageNo++;
      } catch (error) {
        Toast.fail(error);
        this.list = [];
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
    },
    onRefresh() {
      this.pageNo = 1;
      this.onLoad();
    },
    // 查询客户GPS设备经纬度
    async gpsLocation() {
      try {
        let res = await ApiDetail.gpsLocation({
          bizNo: this.bizNo,
        });
        const { data: list } = res;
        this.locationList = list || []
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 按钮事件
    async onActionClick(action, item) {
      try {
        if (action == '导航') {
          let selectImei = this.locationList.find(e => e.imei == item.imei)
          if (selectImei) {
            let { latitude, longitude } = wgs84ToGcj02(parseFloat(selectImei.lat), parseFloat(selectImei.ing))
            goMap(`设备：${selectImei.imei}`, 2, longitude, latitude)
            // goMapName(selectImei.address, 2)
          } else {
            Toast.fail('未查找到设备位置信息');
          }
          return;
        }
        let res = await ApiDetail.gpsWebMap({
          vendor: item.vendorNo,
          imei: item.imei,
          bizNo: item.bizNo,
          type: {
            '查看定位': '1',
            '查看跟踪': '2',
            '查看轨迹': '3',
          }[action]
        });
        if (res.data) {
          window.location.href = res.data;
          // window.open(res.data);
        }
      } catch (error) {
        Toast.fail(error);
      }
    },
  },
  async mounted() {
    await this.gpsLocation();
    await this.onLoad();
  },
};
</script>

<style lang="less" scoped>
.List {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.list-box {
  padding: 10px 12px;

  .item {
    margin-bottom: 15px;
  }
}
</style>
